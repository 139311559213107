import React from "react";
import css from "../componentsStyling/textContainer.module.scss";

interface TextContainerProps {
  label?: string;
  value?: string;
  type?: string;
}

const TextContainer: React.FC<TextContainerProps> = ({
  label = "Label Unset",
  value = "Value Unset",
  type = "text/plain",
}) => {
  return (
    <div className={css.textContainerMain}>
      <div className={css.textLabel}>{label}</div>
      {type === "text/plain" ? (
        <div className={css.textValue}>{value}</div>
      ) : (
        <div className={css.textValue}>
          <img src={value} alt="" />
        </div>
      )}
    </div>
  );
};

export default TextContainer;
