import { Axios, AxiosRequestConfig } from "axios";
import { useState } from "react";

const axConfig: AxiosRequestConfig = {
  baseURL: "https://api.reeqzan.com/Clipboard/",
};
const ax = new Axios(axConfig);

const UseApiCall = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const convertToURLEncoded = (endpoint: string, args: any) => {
    let argsStr: string = "";
    if (args) {
      const allKeys = Object.keys(args);
      const argsArray = allKeys.map(
        (k: string) => `${k}=${encodeURIComponent(args[k])}`
      );
      argsStr = argsArray.join("&");
    }

    if (argsStr) {
      return `${endpoint}?${argsStr}`;
    } else {
      return endpoint;
    }
  };

  const postApi = (endpoint: string, data: any, args: any) => {
    setIsLoading(true);
    let resolve: any = null;
    const prom = new Promise((r) => (resolve = r));

    ax.post(convertToURLEncoded(endpoint, args), data).then((resp) => {
      setIsLoading(false);
      try {
        resolve(JSON.parse(resp.data));
      } catch {
        resolve(resp.data);
      }
    });

    return prom;
  };

  const getApi = <T,>(endpoint: string, args: any): Promise<T> => {
    setIsLoading(true);
    let resolve: any = null;
    const prom = new Promise<T>((r) => (resolve = r));

    ax.get(convertToURLEncoded(endpoint, args)).then((resp) => {
      setIsLoading(false);
      try {
        resolve(JSON.parse(resp.data));
      } catch {
        resolve(resp.data);
      }
    });

    return prom;
  };

  return { getApi, postApi, isLoading };
};

export default UseApiCall;
