import { useEffect, useState } from "react";

import css from "../styling/mainPage.module.scss";
import TextContainer from "../components/textContainer";
import UseApiCall from "../hooks/apiCall";
// import { apiInterface, getApi, postApi } from "../hooks/apiCall";

interface CloudClipboard {
  cbtype: string;
  cbvalue: string;
}

const MainPage = () => {
  const [clipboardType, setClipboardType] = useState<string>("");
  const [clipboardText, setClipboardText] = useState<string>("");
  const [cloudClipboardType, setCloudClipboardType] = useState<string>("");
  const [cloudClipboardText, setCloudClipboardText] = useState<string>("");
  const [clipboardBlob, setClipboardBlob] = useState<Blob | string>("");

  const { getApi, postApi, isLoading } = UseApiCall();

  useEffect(() => {
    window.addEventListener("focus", () => {
      getLocalClipboard();
    });

    getLocalClipboard();
    getCloudClipboard();
  }, []);

  const getLocalClipboard = async () => {
    // Getting the clipboardItem
    const cbItem = await navigator.clipboard.read();
    const cbItemType = cbItem[0].types[0];
    //Convert to blob
    const cbBlob = await cbItem[0].getType(cbItemType);
    setClipboardBlob(cbBlob);
    // clipboardBlob = cbBlob;
    // console.log(cbBlob);
    // console.log(clipboardBlob);

    // Setting the clipboard item
    setClipboardType(cbItemType);

    if (cbItemType.includes("text")) {
      setClipboardText(await cbBlob.text());
    } else {
      setClipboardText(window.URL.createObjectURL(cbBlob));
    }
  };

  const getCloudClipboard = async () => {
    const cloudItem = await getApi<CloudClipboard>("GetBoard", {
      name: "Azizi",
    });
    setCloudClipboardType(cloudItem.cbtype);
    setCloudClipboardText(cloudItem.cbvalue);
  };

  const uploadClipboard = async () => {
    const formData = new FormData();
    formData.append("cbtype", clipboardType);
    if (clipboardType === "text/plain") {
      formData.append("cbtext", clipboardText);
    } else {
      formData.append("cbfile", clipboardBlob);
    }

    await postApi("SaveClipBoard", formData, { name: "Azizi" });
    await getCloudClipboard();
  };

  const copyDownloadClicked = async (type: string) => {
    if (type === "Copy") {
      await navigator.clipboard.writeText(cloudClipboardText);
    } else {
      const anchor = document.createElement("a");
      anchor.href = `${cloudClipboardText}&download=true`;
      anchor.click();
    }

    await getLocalClipboard();
  };

  return (
    <div className={css.mainContainer}>
      <h2>
        Hi Azizi,
        <br />
        this is your global clipboard
      </h2>
      <div className={`${css.clipboardSections}`}>
        <div className={css.clipboardContainer}>
          <div className={css.clipboardTitle}>Local clipboard</div>
          <button onClick={getLocalClipboard}>Refresh</button>
          <TextContainer label="Type" value={clipboardType} />
          <TextContainer
            label="Value"
            value={clipboardText}
            type={clipboardType}
          />
        </div>
        <button onClick={uploadClipboard} disabled={isLoading}>
          Upload
        </button>
        <div className={css.clipboardContainer}>
          <div className={css.clipboardTitle}>Cloud clipboard</div>
          <button onClick={getCloudClipboard}>Refresh</button>
          {isLoading ? (
            <>Loading</>
          ) : (
            <>
              <TextContainer label="Type" value={cloudClipboardType} />
              <TextContainer
                label="Value"
                value={cloudClipboardText}
                type={cloudClipboardType}
              />
              <button
                onClick={(e: any) => copyDownloadClicked(e.target.innerHTML)}
              >
                {cloudClipboardType === "text/plain" ? "Copy" : "Download"}
              </button>
            </>
          )}
        </div>
      </div>
      {/* <button onClick={goToLoginPage}>Test me</button>
      <img src={imgSrc} alt="" /> */}
    </div>
  );
};

export default MainPage;
